<script>
  import { fade, fly } from "svelte/transition";
  import { elasticOut } from "svelte/easing";
  import bgImage from "./assets/BG.jpg";
  import logoImage from "./assets/LOGO.png";

  const SHOW_DELAY_TIME = 300;

  // typewriter effect
  function typewriter(node, { speed = 30, delay }) {
    const valid =
      node.childNodes.length === 1 && node.childNodes[0].nodeType === 3;

    if (!valid) {
      throw new Error(
        `This transition only works on elements with a single text node child`
      );
    }

    const text = node.textContent;
    const duration = text.length * speed;

    return {
      duration,
      delay,
      tick: t => {
        const i = ~~(text.length * t);
        node.innerHTML = text.slice(0, i);
      }
    };
  }
  // logo spin effect
  function spin(node, { duration, delay }) {
    return {
      duration,
      delay,
      css: t => {
        const eased = elasticOut(t);
        return `transform: scale(${eased}) rotate(${eased * 360}deg)`;
      }
    };
  }
  const hasEffect = !localStorage.getItem("no_effect");

  // states
  let visible = false;
  let showTime = false;
  let showSubText = false;
  // show content after image has been loaded
  fetch(bgImage)
    .then(res => res.blob())
    .then(() => {
      showTime = true;
    });
  // setTimeout(() => {
  //   visible = true;
  // }, SHOW_DELAY_TIME);
  const TEXT = `Meam Software Engineering Group was a group of ten passionate software students that was active from 2014 to 2019 exploring and doing web, embedded, web security, video game, networks, graphics, and Android/Windows/Linux projects as well as sharing experiences and ideas.`;
  $: if (showTime) {
    requestAnimationFrame(() => {
      visible = true;
      if (hasEffect) {
        localStorage.setItem("no_effect", true);
      }
    });
  }
</script>

<style>
  :global(body) {
    margin: 0;
    font-family: "AVANGELI";
  }
  .container {
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .container {
    padding: 0 20px;
    color: white;
    font-size: calc(100vh * 0.022);
  }

  @media (orientation: landscape) {
    .container {
      font-size: calc(100vw * 0.022);
    }
  }

  .header {
    position: relative;
    margin-top: 20px;
  }
  .header__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .heading {
    margin: 0;
    padding: 10px 0;
  }
  .content {
    font-family: "Raleway", sans-serif;
  }
  .links {
    display: flex;
    justify-content: space-around;
    width: 150px;
    height: 40px;
  }
  .description {
    text-align: justify;
    text-shadow: 0 4px 6px rgba(32, 33, 36, 0.8);
    margin: 0;
    margin-bottom: 5px;
  }

  .logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 0 20px #fff;
  }
  .link {
    color: #fff;
    font-weight: bold;
  }
  .icon-link {
    width: 40px;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 5px #fff;
  }

  .icon-link > span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: grayscale(1);
    opacity: 0.8;
  }
  @media (hover: none) {
    .icon-link > span {
      filter: initial;
      opacity: initial;
    }
  }
  .icon-link:hover > span {
    filter: initial;
    opacity: initial;
  }
</style>

<svelte:head>
  <title>Meam Software Engineering Group</title>
  <link
    href="https://fonts.googleapis.com/css?family=Raleway&display=swap"
    rel="stylesheet" />
  <link rel="shortcut icon" href={logoImage} />

</svelte:head>

<main
  class="container"
  style="background-image: url('{bgImage}');"
  transition:fade={{ duration: 500 }}>
  {#if visible}
    <header class="header">
      <div class="header__top">
        <img
          class="logo"
          src={logoImage}
          alt="Meam Group Logo"
          in:spin={{ duration: 1500 }} />
        <nav class="links" dir="rtl">
          <a
            class="icon-link"
            href="https://aparat.com/meam_ir"
            target="_blank"
            transition:fly={{ duration: 500, y: -100 }}>
            <span>
              <svg viewBox="0 0 48 48" width="38px" height="38px">
                <path
                  fill="#263238"
                  d="M21.881 6.136l-4.315-.974c-3.52-.795-7.017 1.414-7.812
                  4.934l-.983 4.351C11.603 9.95 16.357 6.788 21.881 6.136zM6.136
                  26.119l-.974 4.315c-.795 3.52 1.414 7.017 4.934
                  7.812l4.351.983C9.95 36.396 6.788 31.643 6.136 26.119zM37.904
                  9.754l-4.351-.983c4.497 2.832 7.659 7.586 8.311
                  13.109l.974-4.315C43.633 14.047 41.424 10.549 37.904
                  9.754zM26.119 41.864l4.315.974c3.52.795 7.017-1.414
                  7.812-4.934l.983-4.351C36.397 38.05 31.643 41.212 26.119
                  41.864z" />
                <path
                  fill="#e91e63"
                  d="M24,8C15.163,8,8,15.163,8,24s7.163,16,16,16s16-7.163,16-16S32.837,8,24,8z
                  M20,13
                  c2.209,0,4,1.791,4,4c0,2.209-1.791,4-4,4s-4-1.791-4-4C16,14.791,17.791,13,20,13z
                  M17,32c-2.209,0-4-1.791-4-4
                  c0-2.209,1.791-4,4-4s4,1.791,4,4C21,30.209,19.209,32,17,32z
                  M22,24c0-1.105,0.895-2,2-2s2,0.895,2,2c0,1.105-0.895,2-2,2
                  S22,25.105,22,24z
                  M28,35c-2.209,0-4-1.791-4-4c0-2.209,1.791-4,4-4s4,1.791,4,4C32,33.209,30.209,35,28,35z
                  M31,24
                  c-2.209,0-4-1.791-4-4c0-2.209,1.791-4,4-4s4,1.791,4,4C35,22.209,33.209,24,31,24z" />
              </svg>
            </span>
          </a>
          <a
            class="icon-link"
            href="https://t.me/meam_ir"
            target="_blank"
            transition:fly={{ duration: 500, delay: 500, y: -100 }}>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="38px"
                height="38px">
                <path
                  fill="#29b6f6"
                  d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z" />
                <path
                  fill="#fff"
                  d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733
                  l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468
                  c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z" />
                <path
                  fill="#b0bec5"
                  d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043
                  l0.964-5.965L23,30.505z" />
                <path
                  fill="#cfd8dc"
                  d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912
                  c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z" />
              </svg>

            </span>
          </a>
          <a
            class="icon-link"
            href="mailto:info(AT)(Meam_Domain)"
            target="_blank"
            transition:fly={{ duration: 500, delay: 1000, y: -100 }}>
            <span>
              <svg
                width="31px"
                height="25px"
                viewBox="0 0 31 25"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>mail</title>
                <desc>Created with Sketch.</desc>
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd">
                  <g id="mail" transform="translate(-2.000000, -5.000000)">
                    <path
                      d="M29.16662,5.833324 L5.833324,5.833324
                      C4.2291599,5.833324 2.93124531,7.1458219
                      2.93124531,8.749986 L2.916662,26.249958
                      C2.916662,27.8541221 4.2291599,29.16662 5.833324,29.16662
                      L29.16662,29.16662 C30.7707841,29.16662
                      32.083282,27.8541221 32.083282,26.249958
                      L32.083282,8.749986 C32.083282,7.1458219
                      30.7707841,5.833324 29.16662,5.833324 Z
                      M29.16662,11.666648 L17.499972,18.958303
                      L5.833324,11.666648 L5.833324,8.749986
                      L17.499972,16.041641 L29.16662,8.749986
                      L29.16662,11.666648 Z"
                      id="Shape"
                      fill="#06264D"
                      fill-rule="nonzero" />
                    <polygon
                      id="Path"
                      points="0 0 34.999944 0 34.999944 34.999944 0 34.999944" />
                  </g>
                </g>
              </svg>
            </span>
          </a>
        </nav>
      </div>
      <h1 class="heading" in:fade={{ delay: hasEffect ? 1500 : 0 }}>
        Meam Group
      </h1>
    </header>
    <article class="content">
      {#if hasEffect}
        <p
          class="description"
          in:typewriter={{ delay: 1550 }}
          on:introend={() => (showSubText = true)}>
          {TEXT}
        </p>
        {#if showSubText}
          <p class="description" transition:fade={{ duration: 1000 }}>
            <a class="link" target="_blank" href="http://makanbama.com/">Makanbama</a>, the startup by Meam, which quickly became highly praised in the
            ecosystem,
            <a
              class="link"
              target="_blank"
              href="http://makanbama.com/goodbye/">ceased operations</a>
            in late 2018.
          </p>
        {/if}
      {:else}
        <p class="description" transition:fade={{ duration: 1000 }}>{TEXT}</p>
        <p
          class="description"
          transition:fade={{ duration: 1000, delay: 1500 }}>
          <a class="link" target="_blank" href="http://makanbama.com/">Makanbama</a>, the startup by Meam, which quickly became highly praised in the
          ecosystem,
          <a class="link" target="_blank" href="http://makanbama.com/goodbye/">ceased operations</a> in late 2018.
        </p>
      {/if}
    </article>
  {/if}
</main>
